@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

/* nav section */

.nav {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
}

.left > h1 {
  font-weight: 500;
}

.left > p {
  font-size: 17px;
  font-weight: 200;
  color: #8F7A7A;
}

.right {
  display: flex;
}

.right > div {
  margin: 0 20px;
}

/* nav section */

/* main section */
.mainContentSection {
  background-image: url("https://images.pexels.com/photos/1714341/pexels-photo-1714341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainContentSectionDiv {  
  text-align: center;
  width: 70%;
  color: white;
}

.mainContentSectionDiv > h2 {
  margin-bottom: 20px;
  font-size: 60px;
  font-weight: lighter;
}
.mainContentSectionDiv > p {
  margin-bottom: 20px;
  line-height: 2;
}

.mainContentSection > button {
  padding: 15px 25px;
  border-radius: 50px;
  border: none;
  background-color: #4782D3;
  color: white;
  font-weight: 800;
}


/* Feature section */

.mainFeatureSection {
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0 0;
}

.featureCardContainer {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.featureCard {
  padding: 20px;
  height: 350px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.featureCardImg {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #4782D3;
  font-size: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featureCardContent {
  text-align: center;
}

.featureCardContent > h2 {
  margin-bottom: 15px;
  font-weight: 300;
}

.featureCardContent > p {
  margin-bottom: 15px;
  font-weight: 300;
  line-height: 1.5em;
  color:#8F7A7A;
  font-size: 18px;
  font-weight: 200;
}

.featureCardContent > a {
  text-decoration: none;
  color: #5394DA;
}

/* social section */
.mainSocialSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
  rgba(0, 0, 0, 0.5)), url("https://images.pexels.com/photos/733856/pexels-photo-733856.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainSocialSectionSubContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.socialCardContainer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.socialCardContainer > .iconFont {
  font-size: 45px;
  margin-bottom: 20px;
}

.socialCardContainer > h1 {
  font-weight: 300;
  margin-bottom: 15px;
}

.socialCardContainer > p {
  font-size: 14px;
}

/* social section */

/* main section */

/* footer section */

.footer {
  background-color: black;
  color: white;
  padding: 50px;
  display: flex;
  gap: 5rem;
}

.footer h3 {
  margin-bottom: 25px;
}

.leftFooter {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}
 
.leftFooter > div p {
  font-weight: 200;  
}

.leftFooterInnerLeft {
  text-align: left;
  width: 280px;
}

.leftFooterInnerLeft > p{
  line-height: 30px;
  color:#CFCFCF;
}
.leftFooterInnerRight > div {
  margin-bottom: 25px;
}

.leftFooterInnerRight > div > p:nth-child(1) {
  margin-bottom: 10px;
  font-size: 18px;
  color:#CFCFCF;
}

.leftFooterInnerRight > div > p:nth-child(2) {
  font-size: 12px;
  color:#CFCFCF;
}

.rightFooter {
  width: 40%;
  padding: 0 20px;
}

.imageFooterRightSection {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.imageFooterRightSection > img {
  height: 100px;
  width: 120px;
}

/* footer section */